@use '../abstract/mixins' as mixin;
@use "../abstract/responsive" as rwd;
$c-white: #FFFFFF;  

// $event-wro-main-color: #e6593a;
$heading-font: "Roboto Slab", sans-serif;
$transition: all .4s ease;  

// NUMBERS SECTION
.numbersSection {
  @include mixin.paddingTopBottom(); 
  background-color: var(--numbers-bg-color);
  color: $c-white;

  ul {
    margin-top: 30px;

    li:last-of-type .boxWithNumber {
      @include rwd.breakpoint(mobile) {
        margin-bottom: 0;
      }
    }

    li {
      @include rwd.breakpoint(mobilelandscape) {
        width: 50%;
      }
    }
  }

  .boxWithNumber {
    width: 100%;
    max-width: 250px;
    margin: 0 auto 50px;
    text-align: center;

    @include rwd.breakpoint(mobile) {
      margin-bottom: 50px;
    }

    span {
      @include mixin.font(18px, 23px, 300);

      @include rwd.breakpoint(mobile) {
        @include mixin.font(16px, 21px);
      }

      em {
        display: block;
        padding-bottom: 20px;
        margin-bottom: 20px;
        position: relative;
        font-style: normal;

        span {
          @include mixin.font(60px, 65px, 800, $c-white, $heading-font);

          @include rwd.breakpoint(tablet) {
            @include mixin.font(50px, 55px);
          }
        }

        @include rwd.breakpoint(mobile) {
          margin-bottom: 15px;
          padding-bottom: 15px;
        }

        &:after {
          content: "";
          position: absolute;
          @include mixin.centerer(true, false);
          bottom: 0;
          width: 40px;
          height: 2px;
          background-color: var(--main-color);
        }
      }
    }
  }

  .showMoreNb {
    display: none;
  }
  .btnWrapper {
    width: 100%;
    text-align: center;
    margin-top: 40px;

    .moreInfoBtn {
      @include mixin.font(16px, false, 700, var(--numbers-more-info-btn-txt));
      @include mixin.border(var(--main-color), 2px);
      padding: 8px 25px;
      display: inline-block;
      border-radius: 3px;
      background-color: var(--numbers-more-info-btn-bg);
      transition: $transition;
      text-decoration: none;

      &:hover,
      &:focus {
        @include rwd.breakpoint(smallUp) {
          background-color: var(--main-color);
        }
      }
    }
  }
}
